import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/auth.read.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuSuperiorComponent } from 'src/app/shared/menu-superior/menu-superior.component';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  submitted = false;
  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private menu: MenuSuperiorComponent) {}
  ngOnInit() {
    this.authenticationService.logout();
  }
  login(form: NgForm) {
    this.authenticationService.login(form).then(response => {
      const token = (<any>response).token;
      this.authenticationService.storeJwtToken(token);
      localStorage.setItem('user', form.value.Email);
      this.menu.carregaDados();
      this.router.navigate(['/monitoramento']);
    }).catch(err => {
      console.log(err.error);
    });

  }
}
