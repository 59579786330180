export class ClimaTempo{
    data:string;
    dia: string;
    minimoAmanhecer: number;
    maximoAmanhecer: number;
    minimoManha: number;
    maximoManha: number;
    minimoTarde: number;
    maximoTarde: number;
    minimoNoite: number;
    maximoNoite: number;
    tempo: number;
    icone: string;
    chanceChuva: number;
    precipitacao:number;
    vvManha:number;
    vrManha:number;
    vvAmanhecer:number;
    vrAmanhecer:number;
    vvTarde:number;
    vrTarde:number;
    vvNoite:number;
    vrNoite:number;
}