import { LoaderService } from './services/loader.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import {JwtModule} from '@auth0/angular-jwt';
import { LoginComponent } from './pages/login/login.component';
import { CondicoesComponent } from './pages/condicoes/condicoes.component';
import { HistoricoComponent } from './pages/historico/historico.component';
import { MonitoramentoComponent } from './pages/Monitoramento/monitoramento.component';
import { AnterioresComponent } from './pages/navios-anteriores/anteriores.component';
import {MenuLateralComponent} from './shared/menu-lateral/menu-lateral.component';
import {MenuSuperiorComponent} from './shared/menu-superior/menu-superior.component';
import { rootRouterConfig } from './app.routes';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { SirenesComponent } from './pages/sirenes/sirenes.component';
import { EmpresasComponent } from './pages/empresas/empresas.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { GraficoComponent } from './shared/grafico/grafico.component';
import { ClimaTempoServiceRead } from './services/clima-tempo-read.service';
import { NavioServiceRead } from './services/navio-read.service';
import { AlarmeServiceRead } from './services/alarmes-read.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guard/auth-guard';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UsuarioServiceRead } from './services/usuario-read.service';
import { RegisterComponent } from './pages/register/register.component';
import { TabuaMareService } from './services/tabua-mares.service';
import { AlertasComponent } from './shared/alertas/alertas.component';
import {ErrorMsgComponent} from './shared/error-msg/error-msg.component';
import { HistoricoTrafegoComponent } from './pages/historico-trafego/historico-trafego.component';
import { TrafegoReadService } from './services/trafego-read.service';
import { RegistroSinistrosComponent } from './pages/registro-sinistros/registro-sinistros.component';
import { SinistroService } from './services/sinistro.service';
import { SimpleModalModule, defaultSimpleModalOptions } from 'ngx-simple-modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { Interceptor } from './interceptors/http-interceptor/http-interceptor.module';
import { AdicionarEmpresaComponent } from './modals/adicionar-empresa/adicionar-empresa';
import { EditarEmpresaComponent } from './modals/editar-empresa/editar-empresa';
import { AdicionarSireneComponent } from './modals/adicionar-sirene/adicionar-sirene';
import { AdicionarUsuarioComponent } from './modals/adicionar-usuario/adicionar-usuario';
import { EditarUsuarioComponent } from './modals/editar-usuario/editar-usuario';
import { ConfirmacaoComponent } from './modals/confirmacao/confirmacao';
import { AvisoErroComponent } from './modals/aviso-erro/aviso-erro';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


export function getToken() {
  return localStorage.getItem('jwt');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CondicoesComponent,
    HistoricoComponent,
    AnterioresComponent,
    MonitoramentoComponent,
    MenuLateralComponent,
    MenuSuperiorComponent,
    ConfiguracoesComponent,
    SirenesComponent,
    EmpresasComponent,
    UsuariosComponent,
    GraficoComponent,
    SpinnerComponent,
    RegisterComponent,
    AlertasComponent,
    ErrorMsgComponent,
    HistoricoTrafegoComponent,
    RegistroSinistrosComponent,
    AdicionarEmpresaComponent,
    EditarEmpresaComponent,
    AdicionarSireneComponent,
    AdicionarUsuarioComponent,
    EditarUsuarioComponent,
    ConfirmacaoComponent,
    AvisoErroComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    Interceptor,
    FormsModule,
    NgbModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken
      }
    }),
    SimpleModalModule,
    NgSelectModule
  ],
  providers: [
    ClimaTempoServiceRead,
    NavioServiceRead,
    AlarmeServiceRead,
    UsuarioServiceRead,
    TrafegoReadService,
    SinistroService,
    TabuaMareService,
    LoaderService,
    AuthGuard,

    JwtHelperService,
    MenuSuperiorComponent,
    HistoricoTrafegoComponent

  ],
  entryComponents: [
    AdicionarEmpresaComponent,
    EditarEmpresaComponent,
    AdicionarSireneComponent,
    AdicionarUsuarioComponent,
    EditarUsuarioComponent,
    ConfirmacaoComponent,
    AvisoErroComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
