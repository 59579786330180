export class Empresa {

  public constructor(init?: Partial<Empresa>) {
    Object.assign(this, init);
  }

  id: number;
  nome: string;
  latMax: number;
  latMin: number;
  lonMax: number;
  lonMin: number;
  velocidadeMax: number;
  comprimentoMax: number;
  larguraMaxAlerta: number;
  distanciaSeguranca: number;
  minutosDuracaoAlarme: number;
  temNavioAtracado?: boolean;
  horarioTesteSirene: number;
  velocidadeSeguranca: number;
  tocandoAlarme: boolean;
}

export class AtualizarNavioAtracado {
  empresas: Empresa[];
}

export class CordenadasEmpresa {
  public constructor(  latMax: number, latMin: number, lonMax: number, lonMin: number, ) {
    this.latMax = latMax;
    this.latMin = latMin;
    this.lonMax = lonMax;
    this.lonMin = lonMin;
  }

  public latMax: number;
  public latMin: number;
  public lonMax: number;
  public lonMin: number;
}
