import { Empresa } from 'src/app/model/empresa';
import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { AdicionarSireneComponent } from '../../modals/adicionar-sirene/adicionar-sirene';
import { Sirene } from 'src/app/model/sirene';
import { EmpresaService } from 'src/app/services/empresa.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SireneService } from 'src/app/services/sirene.service';
import { ConfirmacaoComponent } from 'src/app/modals/confirmacao/confirmacao';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';

@Component({
  selector: 'app-sirenes',
  templateUrl: './sirenes.component.html',
  styleUrls: ['./sirenes.component.css']
})
export class SirenesComponent implements OnInit {

  public Empresas: Empresa[] = [];
  public Sirenes: Sirene[] = [];
  public empresaSelecionada: Empresa;
  public parametrosEmpresaForm: FormGroup;
  public parametrosSireneForm: FormGroup;

  constructor(
    private simpleModalService: SimpleModalService,
    private empresaService: EmpresaService,
    private formBuilder: FormBuilder,
    private sireneService: SireneService
  ) {}

  async ngOnInit() {

    this.carregarDados();

    this.parametrosEmpresaForm = this.formBuilder.group({
      id: [],
      nome: [],
      larguraMaxAlerta: [null, [Validators.required, Validators.min(0)]],
      latMax: [null, [Validators.required]],
      latMin: [null, [Validators.required]],
      lonMax: [null, [Validators.required]],
      lonMin: [null, [Validators.required]],
      minutosDuracaoAlarme: [null, Validators.required],
      velocidadeMax: [null, [Validators.required, Validators.min(0)]],
      comprimentoMax : [null, [Validators.required]],
      distanciaSeguranca: [0, [Validators.min(0)]],
      velocidadeSeguranca: [0, [Validators.min(0)]],
      horarioTesteSirene: [null]
    });

  }

  onChange(empresa: Empresa) {
    this.populaDadosForm(empresa);
    this.listarSirenes(empresa);
  }

  carregarDados() {
    this.empresaService.getEmpresas().subscribe(
      empresas => {
        if (empresas.length <= 0) {
          return;
        }

        this.Empresas = empresas;
        this.empresaSelecionada = this.empresaSelecionada == null
          ? this.Empresas[0] : this.recuperarEmpresaSelecionada(this.empresaSelecionada, empresas);

        this.listarSirenes(this.empresaSelecionada);
        this.populaDadosForm(this.empresaSelecionada);
      },
      error => console.log(error)
    );
  }

  recuperarEmpresaSelecionada(empresa: Empresa, empresas: Empresa[]) {
    return empresas.find(e => e.id === empresa.id);
  }

  listarSirenes(empresa: Empresa) {
    this.sireneService.getSirenes(empresa.id).subscribe(
      sirenesRetornadas => this.Sirenes = sirenesRetornadas,
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }

  populaDadosForm(empresa: Empresa) {
    this.parametrosEmpresaForm.patchValue({
      id: empresa.id,
      nome: empresa.nome,
      comprimentoMax : empresa.comprimentoMax,
      distanciaSeguranca: empresa.distanciaSeguranca,
      horarioTesteSirene: empresa.horarioTesteSirene,
      larguraMaxAlerta: empresa.larguraMaxAlerta,
      latMax: empresa.latMax,
      latMin: empresa.latMin,
      lonMax: empresa.lonMax,
      lonMin: empresa.lonMin,
      minutosDuracaoAlarme: empresa.minutosDuracaoAlarme,
      velocidadeMax: empresa.velocidadeMax,
      velocidadeSeguranca: empresa.velocidadeSeguranca
    });
  }

  testarSirene(sirene: Sirene) {
    const tocandoAlarme = !sirene.tocandoAlarme;
    this.sireneService.testarSirene(sirene).subscribe(
      () => sirene.tocandoAlarme = tocandoAlarme,
      error => {HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error); }
      );
  }

  adicionarSirene() {
    this.simpleModalService.addModal(AdicionarSireneComponent, {
      title: 'Adicionar Sirene',
      empresa: this.empresaSelecionada
    }, { closeOnClickOutside: true }).subscribe(
      result => {
        if (result) { this.listarSirenes(this.empresaSelecionada); }
      }
    );
  }

  onSubmit() {
    if (this.parametrosEmpresaForm.valid) {
      this.atualizarEmpresa();
    } else {
      this.verificaValidacoesForm(this.parametrosEmpresaForm);
    }
  }

  atualizarEmpresa() {
    const empresa = new Empresa(this.parametrosEmpresaForm.value);

    this.empresaService.updateEmpresa(empresa).subscribe(
      () => this.carregarDados(),
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }

  atualizarSirenes() {
    this.sireneService.updateSirenes(this.Sirenes).subscribe(
      () => this.listarSirenes(this.empresaSelecionada),
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }

  excluirSirene(sirene: Sirene) {
    this.simpleModalService.addModal(ConfirmacaoComponent, {
      title: 'Excluir Sirene',
      mensagem: `Deseja realmente excluir a sirene ${sirene.descricao} ?`
    }, {closeOnClickOutside: false}).subscribe(

      (result) => {

        if (result) {

          this.sireneService.excluirSirene(sirene.id).subscribe(
            () => this.listarSirenes(this.empresaSelecionada),
            error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
          );

        }
      });
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

}

