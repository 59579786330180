import { SimpleModalService } from 'ngx-simple-modal';
import { AvisoErroComponent } from '../modals/aviso-erro/aviso-erro';
export class HttpErrorHandler {

  static exibirMensagemHttpError(simpleModalService: SimpleModalService, mensagens: string[] | string) {

  console.log(mensagens);

  try {
    simpleModalService.addModal(AvisoErroComponent, {
      title: '',
      mensagens: mensagens
    }, { closeOnClickOutside: true });
  } catch {
    simpleModalService.addModal(AvisoErroComponent, {
      title: '',
      mensagens: 'Ocorreu um erro inesperado!'
    }, { closeOnClickOutside: true });
  }


  }
}
