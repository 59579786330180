import { Component, OnInit } from '@angular/core';
import { Navio } from 'src/app/model/navio';
import { NavioServiceRead } from 'src/app/services/navio-read.service';
import { validateConfig } from '@angular/router/src/config';

@Component({
  selector: 'app-anteriores',
  templateUrl: './anteriores.component.html'
})
export class AnterioresComponent implements OnInit{
  
  constructor(private navioServiceRead: NavioServiceRead){}
  async ngOnInit() {
  }
  public Navios:Navio[];
  public data:string;
  public horaMin:number = 0;
  public horaMax:number = 23;
  public pesquisa = async()=>{ 
    if (this.data != undefined && this.valida()){
      let ano = this.data.substring(0,4);
      let mes = this.data.substring(5,7);
      let dia = this.data.substring(8,10);
      let data = `${dia}/${mes}/${ano}`;
      this.Navios = await this.preencheNavios(data);
    }
  }
  valida = () =>{
    let aaa:boolean = this.horaMax >= this.horaMin;
    if (!aaa){
      alert("Horas inválidas!");
    }
    return aaa;
  }
  preencheNavios = async(data:string) =>{
    try{
      var response = await this.navioServiceRead.getHistoricoPorData(data,this.horaMin,this.horaMax);
    }catch(error){
      console.log(error);
    }
    return response;
  }
}
