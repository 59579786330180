import { Empresa } from './empresa';

export class Usuario {

  public constructor(init?: Partial<Usuario>) {
    Object.assign(this, init);
  }

  id: number;
  nome: string;
  email: string;
  empresas: Empresa[];
  telefone: string;
  senha: string;
  confirmarSenha: string;
  admin = false;
  visualizador = false;
  alarme = false;
  clima = false;
  navio = false;
}
