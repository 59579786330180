import { CordenadasEmpresa } from './../../model/empresa';
import { Component, OnInit, Inject } from '@angular/core';
import {Navio } from 'src/app/model/navio';
import {NavioServiceRead} from 'src/app/services/navio-read.service';
import { VtExplorer } from 'src/app/model/vt-explorer';
import * as Vec2D from 'vector2d';
declare let L;
const interval = 60000;
@Component({
  selector: 'app-monitoramento',
  templateUrl: './monitoramento.component.html'
})
export class MonitoramentoComponent implements OnInit {
  public cordenadasEmpresas: CordenadasEmpresa[] = [];
  public map;
  public marker;
  public icon;
  public icon_red;
  public markers;
  public distancias;
  public Navios: Navio[] = [];
  public Mapa: VtExplorer[] = [];
  public latlngsGua = [
    [ // 1
      [-23.981842, -46.286681],
      [-23.981950, -46.286697],
      [-23.982122, -46.286735],
      [-23.982286, -46.286740],
      [-23.982406, -46.286751]
    ],
    [ // 2
      [-23.980506, -46.286382],
      [-23.980359, -46.286401],
      [-23.980238, -46.286411],
      [-23.980112, -46.286427],
      [-23.979950, -46.286442],
      [-23.979808, -46.286456],
      [-23.979644, -46.286473],
      [-23.979527, -46.286487],
      [-23.979363, -46.286504],
      [-23.979187, -46.286522],
      [-23.979015, -46.286539],
      [-23.978800, -46.286563]
    ],
    [ // 3
      [-23.975865, -46.286963],
      [-23.975568, -46.287006],
      [-23.975159, -46.287068],
      [-23.974896, -46.287095],
      [-23.974670, -46.287125],
      [-23.974449, -46.287157],
    ],
    [ // 4
      [-23.973782, -46.287541],
      [-23.973417, -46.287713],
      [-23.973245, -46.287799],
      [-23.973042, -46.287896],
      [-23.972814, -46.288001],
      [-23.972513, -46.288135],
      [-23.972320, -46.288217],
    ],
    [
      [-23.971806, -46.288358],
      [-23.971573, -46.288449],
      [-23.971365, -46.288554],
      [-23.971120, -46.288669],
      [-23.970821, -46.288798],
      [-23.970559, -46.288927],
      [-23.970272, -46.289066],
      [-23.969980, -46.289195],
    ],
    [
      [-23.969328, -46.289490],
      [-23.968965, -46.289664],
      [-23.968632, -46.289817],
      [-23.968365, -46.289946],
      [-23.968100, -46.290061],
      [-23.967879, -46.290174],
    ]
  ];
  public latlngsSan = [
    [
      [-23.985146, -46.291930],
      [-23.984838, -46.291867],
      [-23.984534, -46.291808],
      [-23.984299, -46.291760],
      [-23.984039, -46.291701],
      [-23.983809, -46.291658],
      [-23.983574, -46.291599],
      [-23.983334, -46.291551],
      [-23.983045, -46.291497],
      [-23.982687, -46.291438],
      [-23.982314, -46.291363],
      [-23.982461, -46.291379],
      [-23.982108, -46.291320],
      [-23.981765, -46.291272],
      [-23.981442, -46.291202],
      [-23.981153, -46.291132],
      [-23.980854, -46.291057],
      [-23.980545, -46.290987],
      [-23.980222, -46.290928],
      [-23.979923, -46.290858],
      [-23.979761, -46.290809],
      [-23.979549, -46.290864],
      [-23.979277, -46.290958],
      [-23.978973, -46.291038],
      [-23.978627, -46.291145],
      [-23.978257, -46.291258],
      [-23.977823, -46.291387],
    ]
  ];

  constructor(private navioServiceRead: NavioServiceRead) {
  }



  async ngOnInit() {
    this.Mapa = await this.pesquisaMapa();
    this.Navios = await this.pesquisaNavio();
    this.map = L.map('map').setView([-23.975019, -46.288961], 15);
    //this.map.dragging.disable();
    //this.map.touchZoom.disable();
    //this.map.doubleClickZoom.disable();
    //this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    this.map.zoomControl.disable();
    if (this.map.tap) { this.map.tap.disable(); }
    this.markers = L.layerGroup();
    this.distancias = L.layerGroup();
    document.getElementById('map').style.cursor = 'default';
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      interactive: false
    }).addTo(this.map);
    this.icon = L.icon({
      iconUrl: 'assets/leaflet/images/ship-icon.png',
      shadowUrl: 'assets/leaflet/images/marker-shadow.png',
      iconAnchor:   [13, 39], // point of the icon which will correspond to marker's location
      shadowAnchor: [13, 39],  // the same for the shadow
      popupAnchor:  [0, -39] // point from which the popup should open relative to the iconAnchor
    });
    this.icon_red = L.icon({
      iconUrl: 'assets/leaflet/images/ship-icon-red.png',
      shadowUrl: 'assets/leaflet/images/marker-shadow.png',
      iconAnchor:   [13, 39], // point of the icon which will correspond to marker's location
      shadowAnchor: [13, 39],  // the same for the shadow
      popupAnchor:  [0, -39] // point from which the popup should open relative to the iconAnchor
    });

    this.preencheMapa();
    this.desenharAreasEmpresasNoMapa();
    this.desenharLimitesGuarujaNoMapa();
    this.desenharLimitesSantosNoMapa();

    setInterval(() => {
        this.atualizaMapa();
    }, interval);

    // var bounds = [[-23.982061, -46.291350],[-23.980042,-46.290278]];
    // const bounds = [[-23.98264, -46.29142], [-23.97982, -46.28953]];
    // L.rectangle(bounds, {color: '#ff7800', weight: 1}).addTo(this.map);
    // zoom the map to the rectangle bounds
  }

    public preencheMapa = () => {
      this.markers.remove();
      this.markers = new L.layerGroup;
      this.distancias.remove();
      this.distancias = new L.layerGroup;
      if (this.Mapa != null) {
        this.Mapa.forEach((item) => {
          if (item.speed >= 9 && item.length >= 290 && item.width >= 45) {
            this.marker = new L.marker([item.latitude, item.longitude], {icon: this.icon_red});
          } else {
            this.marker = new L.marker([item.latitude, item.longitude], {icon: this.icon});
          }
            // tslint:disable-next-line: max-line-length
            this.marker.bindPopup(`Navio:${item.name}<br> Velocidade:${item.speed} nós<br> Tamanho: ${item.length}m x ${item.width}m<br>Destino: ${item.destination}<br>Tonelagem: ${item.gt}t`).openPopup();
            this.markers.addLayer(this.marker);
            this.verificarDistancias(item);
        });
        this.map.addLayer(this.markers);
        this.map.addLayer(this.distancias);

      }
    }

  desenharAreasEmpresasNoMapa() {
    this.cordenadasEmpresas.map(coordenadasEmpresa => {
      const bounds = [[coordenadasEmpresa.latMin, coordenadasEmpresa.lonMin], [coordenadasEmpresa.latMax, coordenadasEmpresa.lonMax]];
      L.rectangle(bounds, {color: '#ff7800', weight: 1}).addTo(this.map);
    });
  }

   Calcdistance(origin, destination) {
    // return distance in meters
    const lon1 = this.toRadian(origin[1]),
        lat1 = this.toRadian(origin[0]),
        lon2 = this.toRadian(destination[1]),
        lat2 = this.toRadian(destination[0]);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
  }

  toRadian(degree) {
  return degree * Math.PI / 180;
  }

  desenharLimitesGuarujaNoMapa() {
    L.polyline(this.latlngsGua, {color: 'black'}).addTo(this.map);
  }

  desenharLimitesSantosNoMapa() {
    L.polyline(this.latlngsSan, {color: 'black'}).addTo(this.map);
  }

  verificarDistancias(item) {
    const pontoSan = this.pegarPontoMaisProximoBordaSantos([item.latitude, item.longitude]);
    const distSan = this.Calcdistance([item.latitude, item.longitude], [pontoSan.x, pontoSan.y]);
    console.log('Distancia Santos', distSan);
    const pontoGua = this.pegarPontoMaisProximoBordaGuaruja([item.latitude, item.longitude]);
    const distGua = this.Calcdistance([item.latitude, item.longitude], [pontoGua.x, pontoGua.y])
    console.log('Distancia Guaruja', distGua);
    if (distGua < distSan) {
      const linha = new L.polyline([[item.latitude, item.longitude], [pontoGua.x, pontoGua.y]], {color: 'blue'});
      console.log(this.map)
      const popup = L.popup();
      popup.setContent(Math.round(distGua) + 'm');
      linha.bindPopup(popup);
      linha.on('click mouseover', function () {
        linha.openPopup();
      });
      this.distancias.addLayer(linha);
    } else {
      const linha = new L.polyline([[item.latitude, item.longitude], [pontoSan.x, pontoSan.y]], {color: 'blue'});
      console.log(this.map)
      const popup = L.popup();
      popup.setContent(Math.round(distSan) + 'm');
      linha.bindPopup(popup);
      linha.on('click mouseover', function () {
        linha.openPopup();
      });
      this.distancias.addLayer(linha);
    }
  }

  pegarPontoMaisProximoBordaSantos(CoordNavio) {
    let pontoMaisPerto;
    let menor = Number.MAX_VALUE;
    for (let i = 0; i < this.latlngsSan.length; i++) {
      const costado = this.latlngsSan[i];
      costado.forEach((coord) => {
      const ponto = new Vec2D.Vector(coord[0], coord[1]);
      const navio = new Vec2D.Vector(CoordNavio[0], CoordNavio[1]);
      const distancia = navio.distance(ponto);
      if (distancia < menor) {
        menor = distancia;
        pontoMaisPerto = ponto;
      }
    });
  }
    return pontoMaisPerto;
  }

  pegarPontoMaisProximoBordaGuaruja(CoordNavio) {
    let pontoMaisPerto;
    let menor = Number.MAX_VALUE;
    for (let i = 0; i < this.latlngsGua.length; i++) {
      const costado = this.latlngsGua[i];
      costado.forEach((coord) => {
      const ponto = new Vec2D.Vector(coord[0], coord[1]);
      const navio = new Vec2D.Vector(CoordNavio[0], CoordNavio[1]);
      const distancia = navio.distance(ponto);
      if (distancia < menor) {
        menor = distancia;
        pontoMaisPerto = ponto;
      }
    });
  }
    return pontoMaisPerto;
  }




  public url(destino: string): string {
    let icone = 'arrow-down';
    if (destino === 'SANTOS') {
        icone = 'arrow-up';
    }
    return 'assets/images/' + icone + '.png';
  }
  pesquisaMapa = async() => {
    let response: VtExplorer[] = [];
    try {
      const requestReponse = await this.navioServiceRead.getVtExplorer();

      response = requestReponse.response;

      this.cordenadasEmpresas = requestReponse.empresas.map<CordenadasEmpresa>(
        empresa =>
          new CordenadasEmpresa(
          empresa.latMax,
          empresa.latMin,
          empresa.lonMax,
          empresa.lonMin));

    } catch (error) {
      console.log(error);
    }
    return response;
  }

  pesquisaNavio = async() => {
    let response: Navio[] = [];
    try {
      response = await this.navioServiceRead.getHistoricoPorUsuario();
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  public atualizaMapa = async () => {
    this.Mapa = await this.pesquisaMapa();
    this.Navios = await this.pesquisaNavio();
    this.preencheMapa();
  }

}
