export class Sirene {

  public constructor(init?: Partial<Sirene>) {
    Object.assign(this, init);
  }

  id: number;
  descricao: string;
  empresaId: number;
  telefone: string;
  comandoLigar: string;
  comandoDesligar: string;
  ativa = true;
  tocandoAlarme = false;
}
