import { UsuarioService } from './../../services/usuario.service';
import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { AdicionarUsuarioComponent } from '../../modals/adicionar-usuario/adicionar-usuario';
import { EditarUsuarioComponent } from '../../modals/editar-usuario/editar-usuario';
import { Usuario } from 'src/app/model/usuario';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';
import { ConfirmacaoComponent } from 'src/app/modals/confirmacao/confirmacao';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {
  public usuarios: Usuario[] = [];

  constructor(
    private simpleModalService: SimpleModalService,
    private usuarioService: UsuarioService
  ) {}

  ngOnInit() {
    this.listarUsuarios();
  }

  adicionarUsuario() {
    this.simpleModalService.addModal(AdicionarUsuarioComponent, {
      title: 'Adicionar Usuario'
    }, { closeOnClickOutside: true }).subscribe(
      ((result) => {
        if (result) {
          this.listarUsuarios();
        }
      })
    );
  }

  editarUsuario(usuario: Usuario) {
    this.simpleModalService.addModal(EditarUsuarioComponent, {
      title: 'Editar Usuário', usuario: usuario
    }, { closeOnClickOutside: true }).subscribe((result) => {
      if (result) {
        this.listarUsuarios();
      }
    });
  }

  listarUsuarios() {
    this.usuarioService.getUsuarios().subscribe(
      usuarios => this.usuarios = usuarios,
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }

  excluirUsuario(usuario: Usuario) {
    this.simpleModalService.addModal(ConfirmacaoComponent, {
      title: 'Excluir Usuário',
      mensagem: `Deseja realmente excluir o usuário ${usuario.nome} ?`
    }, {closeOnClickOutside: false}).subscribe(

      (result) => {

        if (result) {

          this.usuarioService.deleteUsuario(usuario.id).subscribe(
            () => this.listarUsuarios(),
            error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
          );
        }
      });
  }

}
