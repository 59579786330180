import { Component, OnInit } from '@angular/core';
import { SinistroService } from 'src/app/services/sinistro.service';
import { Sinistro } from 'src/app/model/sinistro';


@Component({
  selector: 'app-registro-sinistros',
  templateUrl: './registro-sinistros.component.html',
  styleUrls: ['./registro-sinistros.component.css']
})
export class RegistroSinistrosComponent implements OnInit {

  public sinistros: Sinistro[] = [];
  constructor(private sinistroService: SinistroService) {
    
   }

  public data: string;
  public hora: string;
  public observacao: string;

  async ngOnInit() {
    this.sinistros = await this.sinistroService.getSinistros().then(sinistros => {
      sinistros.forEach(registro => {
        let ano = registro.data.substring(0,4);
        let mes = registro.data.substring(5,7);
        let dia = registro.data.substring(8,10);
        let hora = registro.data.substring(11,16);
        let data = `${dia}/${mes}/${ano} ${hora}`;
        registro.data = data;
        return registro;
      });
      return sinistros;
    });
  }

  public navios: any[] = [];
  public navio: any;

  
  getNavios = async() =>{
    if(this.data.length == 10) {
      let ano = this.data.substring(0,4);
      let mes = this.data.substring(5,7);
      let dia = this.data.substring(8,10);
      let data = `${ano}-${mes}-${dia}`;
      this.navios = await this.sinistroService.getNaviosSinistro(data);
    }
  }

  async submit ()  {
    if(this.hora && this.data && this.navio)
    {
      let sinistro:Sinistro = this.populaSinistro();
      await this.sinistroService.postSinistro(sinistro);
      alert("Registro inserido com sucesso. Atualize a tela de sinistros pra verificar seu registro.");
    }
    else {
      alert("Favor inserir mais informações sobre seu sinistro.");
    }
  }

  populaSinistro() {
    let sinistroform:Sinistro = new Sinistro();
    let formattedDate:string = `${this.data} ${this.hora}`
    let user = localStorage.getItem("user");

    if(this.observacao == null || this.observacao == undefined) {
      this.observacao = "";
    }

    sinistroform.data = formattedDate;
    sinistroform.descricao = this.observacao;
    sinistroform.nomenavio = this.navio.name;
    sinistroform.dononavio = this.navio.owner;
    sinistroform.largura = this.navio.width;
    sinistroform.comprimento = this.navio.length;
    sinistroform.imo = this.navio.imo;
    sinistroform.destino = this.navio.destination;
    sinistroform.usuario = user;

    return sinistroform;

  }

}
