import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {HistoricoTrafego} from "src/app/model/historico-trafego";
import {environment } from "src/environments/environment";

@Injectable()
export class TrafegoReadService{
    constructor(private http:HttpClient){}

    public getTrafego = async(data:string,nome:string): Promise<HistoricoTrafego[]> => {
        let token = localStorage.getItem("jwt");
        let email = localStorage.getItem("user");
        const url = `${environment.api_read}HistoricoNavios/getTrafego?data=${data}&nome=${nome}`;
        return this.http.get<HistoricoTrafego[]>(url).toPromise();
    }

    public getNavios = async(data:string) : Promise<string[]> => {
        const url = `${environment.api_read}HistoricoNavios/getNavios?data=${data}`
        return this.http.get<string[]>(url).toPromise();
    }
}