import { Sirene } from 'src/app/model/sirene';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SireneService {

  constructor(private http: HttpClient) {}

  public getSirenes(empresaId: number) {

    const url = `${environment.api_read}Sirene/getPorEmpresa?empresaId=${empresaId}`;

    return this.http.get(url)
      .pipe(
        map((response: {success: boolean, data: Sirene[]}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public postSirene(sirene: Sirene) {
    const url = `${environment.api_read}Sirene/post`;

    return this.http.post(url, sirene)
      .pipe(
        map((response: {success: boolean, data: Sirene[]}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public updateSirenes(sirenes: Sirene[]) {
    const url = `${environment.api_read}Sirene/put`;

    return this.http.put(url, {'sirenes': sirenes})
      .pipe(
        map((response: {success: boolean, data: Sirene[]}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public excluirSirene(sireneId: number) {
    const url = `${environment.api_read}Sirene/delete?sireneId=${sireneId}`;

    return this.http.delete(url)
      .pipe(
        map((response: {success: boolean, data: Sirene}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
     );
  }

  public testarSirene(sirene: Sirene) {
    const url = `${environment.api_read}Sirene/TestarSirene`;

    return this.http.post(url, sirene)
      .pipe(
        map((response: {success: boolean, data: any}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
     );
  }

}
