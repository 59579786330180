import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {ClimaTempo} from "src/app/model/clima-tempo";
import {ClimaTempoHoje} from "src/app/model/clima-tempo-hoje";
import {environment } from "src/environments/environment";
import { ClimaDiario } from "../model/clima-diario";
import { AlertaAtivos } from "../model/alertas-ativos";

@Injectable()
export class ClimaTempoServiceRead{
    token = localStorage.getItem('jwt');

    constructor(private http:HttpClient){}
    public getPrevisao = async(): Promise<ClimaTempo[]> => {
        const url = `${environment.api_read}ClimaTempo/getPrevisao`;
        return this.http.get<ClimaTempo[]>(url).toPromise();
    }
    public getPrevisaoSemana = async(): Promise<ClimaTempo[]> => {
        const url = `${environment.api_read}ClimaTempo/getPrevisaoSemana`;
        return this.http.get<ClimaTempo[]>(url).toPromise();
    }
    public getPrevisaoHoje = async(): Promise<ClimaTempo[]> => {
        const url = `${environment.api_read}ClimaTempo/getPrevisaoHoje`;
        return this.http.get<ClimaTempo[]>(url).toPromise();
    }
    public getPrevisaoProximoDias = async(): Promise<ClimaTempo[]> => {
        const url = `${environment.api_read}ClimaTempo/getPrevisaoProximoDias`;
        return this.http.get<ClimaTempo[]>(url).toPromise();
    }
    public getPrevisaoAgora = async(): Promise<ClimaDiario> => {
        const url = `${environment.api_read}ClimaTempo/getPrevisaoAgora`;
        return this.http.get<ClimaDiario>(url).toPromise();
    }
    public getAlertas = async(): Promise<AlertaAtivos> => {
        const url = `${environment.api_read}ClimaTempo/getAlertas`;
        return this.http.get<AlertaAtivos>(url).toPromise();
    }
}