import { Empresa } from './../../model/empresa';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmpresaService } from 'src/app/services/empresa.service';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';
export interface AlertModel {
  title: string;
  empresa: Empresa;
}
@Component({
    selector: 'confirm',
    templateUrl: './editar-empresa.html'
})
export class EditarEmpresaComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {

  title: string;
  empresaForm: FormGroup;
  empresaId: number;
  empresa: Empresa;

  constructor(private formBuilder: FormBuilder,
    private empresaService: EmpresaService,
    private simpleModalService: SimpleModalService) {
    super();
  }

  ngOnInit() {
    this.empresaForm = this.formBuilder.group({
      id: [],
      nome: [null, Validators.required],
      larguraMaxAlerta: [null, [Validators.required, Validators.min(0)]],
      latMax: [null, [Validators.required]],
      latMin: [null, [Validators.required]],
      lonMax: [null, [Validators.required]],
      lonMin: [null, [Validators.required]],
      minutosDuracaoAlarme: [null, Validators.required],
      velocidadeMax: [null, [Validators.required, Validators.min(0)]],
      comprimentoMax : [null, [Validators.required]],
      distanciaSeguranca: [0, [Validators.min(0)]],
      velocidadeSeguranca: [0, [Validators.min(0)]],
      horarioTesteSirene: [null]
    });

    this.populaDadosForm(this.empresa);
  }

  populaDadosForm(empresa: Empresa) {
    this.empresaForm.patchValue({
      id: empresa.id,
      nome: empresa.nome,
      comprimentoMax : empresa.comprimentoMax,
      distanciaSeguranca: empresa.distanciaSeguranca,
      horarioTesteSirene: empresa.horarioTesteSirene,
      larguraMaxAlerta: empresa.larguraMaxAlerta,
      latMax: empresa.latMax,
      latMin: empresa.latMin,
      lonMax: empresa.lonMax,
      lonMin: empresa.lonMin,
      minutosDuracaoAlarme: empresa.minutosDuracaoAlarme,
      velocidadeMax: empresa.velocidadeMax,
      velocidadeSeguranca: empresa.velocidadeSeguranca
    });
  }

  onSubmit() {
    if (this.empresaForm.valid) {
      this.updateEmpresa();
    } else {
      this.verificaValidacoesForm(this.empresaForm);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  initEmpresa(): Empresa {
    return  new Empresa(this.empresaForm.value);
  }

  updateEmpresa() {
    const empresa = this.initEmpresa();
    this.empresaService.updateEmpresa(empresa).subscribe(
      response => {
        console.log(response);
        this.result = true;
        this.close();
      },
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }
}
