import { Component, OnInit } from '@angular/core';
import { HistoricoTrafego } from 'src/app/model/historico-trafego';
import { TrafegoReadService } from 'src/app/services/trafego-read.service';

declare let L;
@Component({
  selector: 'app-historico-trafego',
  templateUrl: './historico-trafego.component.html',
  styleUrls: ['./historico-trafego.component.css']
})
export class HistoricoTrafegoComponent implements OnInit{
  public map;
  public markers;
  public marker;
  public icon;
  public points = [];
  public polyline = L.polyline(this.points);

  ngOnInit() {
    this.map = L.map('map').setView([-23.9822858, -46.292518], 13);
    this.markers = L.layerGroup();
    document.getElementById('map').style.cursor='default';
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      interactive: false
    }).addTo(this.map);
    this.navios[0] = "Selecione um navio";
    this.nomeNavio = this.navios[0];
  }

  constructor(private trafegoReadService:TrafegoReadService) { }
  public HistoricoTrafego:HistoricoTrafego[] = [];
  public navios = [];
  
  public data:string;
  public nomeNavio:string;
  
  public getNavios = async() =>{
    let ano = this.data.substring(0,4);
    let mes = this.data.substring(5,7);
    let dia = this.data.substring(8,10);
    let data = `${ano}-${mes}-${dia}`;
    this.navios = await this.trafegoReadService.getNavios(data);
  }
  public pesquisa = async()=>{ 
    if (this.HistoricoTrafego.length > 0){
      this.map.removeLayer(this.polyline);
      this.points = [];
    }
    if (this.data != undefined && this.nomeNavio != undefined && this.nomeNavio != ''){
      let ano = this.data.substring(0,4);
      let dia = this.data.substring(5,7);
      let mes = this.data.substring(8,10);
      let data = `${dia}/${mes}/${ano}`;
      this.HistoricoTrafego = await this.preencheHistoricoTrafego(data,this.nomeNavio);
       this.markers.remove();
      this.markers = new L.layerGroup;
      this.icon = L.icon({
        iconUrl: 'assets/leaflet/images/ship-icon.png',
        shadowUrl: 'assets/leaflet/images/marker-shadow.png',
        iconAnchor:   [13, 39], // point of the icon which will correspond to marker's location
        shadowAnchor: [13, 39],  // the same for the shadow
        popupAnchor:  [0, -39] // point from which the popup should open relative to the iconAnchor
      });
      if (this.HistoricoTrafego != null){
        this.HistoricoTrafego.forEach((item)=>{
            this.points.push([item.latitude, item.longitude]);
            this.marker = new L.marker([item.latitude,item.longitude],{icon:this.icon});
            this.marker.bindPopup(`Navio:${item.nome}<br> Velocidade:${item.velocidade}<br> Data ${item.data}<br>`).openPopup();
            this.markers.addLayer(this.marker);
        })
        this.map.addLayer(this.markers);
        this.polyline = L.polyline(this.points);
        this.polyline.addTo(this.map);
      }
    }else{
      alert('Seleciona uma data e um navio!');
    }
  }
  
  preencheHistoricoTrafego = async(data:string,nomeNavio:string)=>{
    try{
      var response = await this.trafegoReadService.getTrafego(data,nomeNavio);
    }catch(error){
      console.log(error);
    }
    return response;
  }
}
