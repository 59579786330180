import { Empresa } from 'src/app/model/empresa';
import {Injectable, OnInit} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HistoricoAlarme} from 'src/app/model/historico-alarme';
import {environment } from 'src/environments/environment';


@Injectable()
export class UsuarioServiceRead {
    token;
    email;
    public carregaStorage() {
        this.token = localStorage.getItem('jwt');
        this.email = localStorage.getItem('user');
    }

    constructor(private http: HttpClient) {}

    public getHistoricoAlarme = async(): Promise<HistoricoAlarme[]> => {
        this.carregaStorage();
        const url = `${environment.api_read}HistoricoAlarmes/getPorUsuario?email=${this.email}`;
        return this.http.get<HistoricoAlarme[]>(url).toPromise();
    }
    public setAlarme = async(): Promise<boolean> => {
        const url = `${environment.api_read}Usuario/setAlarme`;
        return this.http.get<boolean>(url).toPromise();
    }
    public getAlarme = async(): Promise<Empresa[]> => {
        this.carregaStorage();
        const url = `${environment.api_read}Usuario/getAlarmeV2`;
        return this.http.get<Empresa[]>(url).toPromise();
    }
    public testAlarme = async(): Promise<boolean> => {
        this.carregaStorage();
        const url = `${environment.api_read}Usuario/testarAlarme?email=${this.email}`;
        return this.http.get<boolean>(url).toPromise();
    }
}
