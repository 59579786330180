import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {HistoricoAlarme} from "src/app/model/historico-alarme";
import {environment } from "src/environments/environment";


@Injectable()
export class AlarmeServiceRead{
    constructor(private http:HttpClient){}

    public getHistoricoAlarme = async(): Promise<HistoricoAlarme[]> => {
        let token = localStorage.getItem("jwt");
        let email = localStorage.getItem("user");
        const url = `${environment.api_read}HistoricoAlarmes/getPorUsuario?email=${email}`;
        return this.http.get<HistoricoAlarme[]>(url).toPromise();
    }
}