import { LoaderService } from './../../services/loader.service';
import { Component, AfterContentChecked, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-loader',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterContentChecked {

  isLoading: Subject<boolean>;
  color = 'red';
  mode = 'indeterminate';
  value = 50;

  constructor(private loaderService: LoaderService) {}

  ngAfterContentChecked(): void {
    this.isLoading = this.loaderService.isLoading;
  }

}
