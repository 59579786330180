import { AtualizarNavioAtracado } from './../model/empresa';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Empresa } from '../model/empresa';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) {}

  public postEmpresa(empresa: Empresa) {

    const url = `${environment.api_read}Empresa/Criar`;

    return this.http.post(url, empresa)
      .pipe(
        map((response: {success: boolean, data: Empresa}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public updateEmpresa(empresa: Empresa) {
    const url = `${environment.api_read}Empresa/Atualizar`;

    return this.http.put(url, empresa)
      .pipe(
        map((response: {success: boolean, data: Empresa}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }
  public atualizarNavioAtracado(atualizarNavioAtracado: AtualizarNavioAtracado) {
    const url = `${environment.api_read}Empresa/AtualizarNavioAtracado`;

    return this.http.put(url, atualizarNavioAtracado)
      .pipe(
        map((response: {success: boolean, data: Empresa[]}) => response.data),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            return throwError({succes: false, data: ['Ação permitida apenas para vizualizadores']});
          } else {
            return throwError(error.error);
          }
        }),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public getEmpresas() {

    const url = `${environment.api_read}Empresa/Listar`;

    return this.http.get(url)
      .pipe(
        map((response: {success: boolean, data: Empresa[]}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public getEmpresa(id: number) {
    const url = `${environment.api_read}Empresa/Obter?id=${id}`;

    return this.http.get(url)
      .pipe(
        map((response: {success: boolean, data: Empresa}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );

  }

  public deleteEmpresa (id: number) {

    const url = `${environment.api_read}Empresa/Excluir?empresaId=${id}`;

    return this.http.delete(url)
    .pipe(
      map((response: {success: boolean, data: string}) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
      catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
  );
  }
}
