import {Injectable, OnInit} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Navio} from 'src/app/model/navio';
import {VtExplorer} from 'src/app/model/vt-explorer';
import {environment } from 'src/environments/environment';
import { Empresa } from '../model/empresa';


@Injectable()
export class NavioServiceRead {
    token = localStorage.getItem('jwt');
    email = localStorage.getItem('user');

    constructor(private http: HttpClient) {}

    public get = async(): Promise<Navio[]> => {
        const url = `${environment.api_read}Navios/get`;
        return this.http.get<Navio[]>(url).toPromise();
    }
    public getVtExplorer = async(): Promise<{response: VtExplorer[], empresas: Empresa[]}> => {
        const url = `${environment.api_read}Navios/getMarineTraffic`;
        return this.http.get<{response: VtExplorer[], empresas: Empresa[]}>(url).toPromise();
    }
    public getHistorico = async(): Promise<Navio[]> => {
        const url = `${environment.api_read}HistoricoNavios/get`;
        return this.http.get<Navio[]>(url).toPromise();
    }
    public getHistoricoPorUsuario = async(): Promise<Navio[]> => {
        const url = `${environment.api_read}HistoricoNavios/getPorUsuario?email=${this.email}`;
        return this.http.get<Navio[]>(url).toPromise();
    }
    public getHistoricoPorData = async(data: string, menor: number, maior: number): Promise<Navio[]> => {
        const url = `${environment.api_read}HistoricoNavios/getPorData?email=${this.email}&data=${data}&menor=${menor}&maior=${maior}`;
        return this.http.get<Navio[]>(url).toPromise();
    }

}
