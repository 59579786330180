import { EmpresaService } from './../../services/empresa.service';
import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { AdicionarEmpresaComponent } from '../../modals/adicionar-empresa/adicionar-empresa';
import { EditarEmpresaComponent } from '../../modals/editar-empresa/editar-empresa';
import { ConfirmacaoComponent } from '../../modals/confirmacao/confirmacao';
import { Empresa } from 'src/app/model/empresa';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html'
})

export class EmpresasComponent implements OnInit {
  public Empresas: Empresa[] = [];

  constructor(private simpleModalService: SimpleModalService,
    private empresaService: EmpresaService) {}

  ngOnInit() {
    this.carregarEmpresas();
  }

  adicionarEmpresa() {
    this.simpleModalService.addModal(AdicionarEmpresaComponent, {
      title: 'Adicionar Empresa'
    }, { closeOnClickOutside: true }).subscribe((result) => {
        if (result) {
          this.carregarEmpresas();
        }
    });
  }

  editarEmpresa(empresa: Empresa) {
    this.simpleModalService.addModal(EditarEmpresaComponent, {
      title: 'Editar Empresa', empresa: empresa
    }, { closeOnClickOutside: true }).subscribe((result) => {
      if (result) {
        this.carregarEmpresas();
      }
    });
  }

  abrirModalConfirmacao(empresa: Empresa) {
    this.simpleModalService.addModal(ConfirmacaoComponent, {
      title: 'Excluir Empresa',
      mensagem: `Deseja realmente excluir a empresa ${empresa.nome} ?`
    }, {closeOnClickOutside: false}).subscribe((result) => {
      if (result) {
        this.excluirEmpresa(empresa.id);
      }
    });
  }

  excluirEmpresa(id: number) {
    this.empresaService.deleteEmpresa(id)
      .subscribe(
        () => this.carregarEmpresas(),
        error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error),
      );
  }

  carregarEmpresas() {
    this.empresaService.getEmpresas()
      .subscribe(
        empresas => this.Empresas = empresas,
        error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
      );
  }
}
