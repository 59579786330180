import { Component, OnInit } from '@angular/core';
import {TabuaMares} from 'src/app/model/tabua-mares';
import { TabuaMareService } from 'src/app/services/tabua-mares.service';
import { Atual } from 'src/app/model/atual';
@Component({
  selector: 'app-condicoes',
  templateUrl: './condicoes.component.html'
})

export class CondicoesComponent implements OnInit{
  public tabuaMares = [];
  public atual: Atual = new Atual;
  async ngOnInit() {
    this.tabuaMares = await this.pesquisaTabuaMare();
    this.atual = await this.pesquisaAtual();
  }
  igual(valor){
    return valor 
  }
  constructor(private tabuaMareServiceRead: TabuaMareService){}
  pesquisaTabuaMare = async() =>{
    try{
      var response = await this.tabuaMareServiceRead.getTabuaMare();
    }catch(error){
      console.log(error);
    }
    return response;
  }
  pesquisaAtual = async() => {
    try{
      var response = await this.tabuaMareServiceRead.getAtual();
    }catch(error){
      console.log(error);
    }
    return response;
  }
}

