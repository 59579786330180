import { LoaderService } from './../../services/loader.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { finalize, catchError, switchMap, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/auth.read.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class HttpInterceptorModule implements HttpInterceptor {

  email = localStorage.getItem('user');
  isRefreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private requestsWithoutLoader: string[] = [
    `${environment.api_read}ClimaTempo/getAlertas`,
    `${environment.api_read}ClimaTempo/getPrevisaoAgora`,
    `${environment.api_read}ClimaTempo/getPrevisaoProximoDias`,
    `${environment.api_read}Usuario/getAlarmeV2`,
    `${environment.api_read}ClimaTempo/getPrevisaoProximoDias`,
    `${environment.api_read}Navios/getMarineTraffic`,
    `${environment.api_read}HistoricoNavios/getPorUsuario?email=${this.email}`
  ];

  constructor(private loaderService: LoaderService,
    private authService: AuthenticationService) { }

  exibirLoader() {
    this.loaderService.show();
  }

  esconderLoader() {
    this.loaderService.hide();
  }

  handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {

      this.isRefreshing = true;

      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.data.token);
          return next.handle(this.addToken(request, response.data.token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      req = this.addToken(req, this.authService.getJwtToken());
    }

    if (!this.requestsWithoutLoader.find(request => request === req.url) ||
        !this.requestsWithoutLoader[6].includes(`${environment.api_read}HistoricoNavios/getPorUsuario?email`)) {

        this.exibirLoader();
    }

    return next.handle(req)
    .pipe(
        finalize(() => {
          if (!this.requestsWithoutLoader.find(request => request === req.url) ||
          !this.requestsWithoutLoader[6].includes(`${environment.api_read}HistoricoNavios/getPorUsuario?email`)) {

            this.esconderLoader();

          }
        }),
        catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401 && !error.url.includes('Login')) {
                return this.handleUnauthorizedError(req, next);
            } else {
                return throwError(error);
            }
        }),
    );

  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorModule,
      multi: true,
    },
  ],
})


export class Interceptor { }
