import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ClimaTempoServiceRead } from "src/app/services/clima-tempo-read.service";
import { ClimaTempoHoje } from "src/app/model/clima-tempo-hoje";
import { ClimaDiario } from "src/app/model/clima-diario";
import { ClimaTempo } from "src/app/model/clima-tempo";
import { AlertaAtivos } from "src/app/model/alertas-ativos";

@Component({
    selector: 'app-alertas',
    templateUrl: './alertas.component.html'
  })
  export class AlertasComponent implements OnInit{
      public alertas:AlertaAtivos;
      public alertaChuva:boolean;
      public alertaVento:boolean;
      public alertaRaio:boolean;
      public nivel:string;
    async ngOnInit() {
        this.alertas = await this.pesquisaAlertas();
        this.alertaChuva = this.alertas.chuva;
        this.alertaRaio = this.alertas.raio;
        this.alertaVento = this.alertas.vento;
        this.nivel = this.alertas.nivel;
    }
    constructor(public router:Router,private climaTempoServiceRead:ClimaTempoServiceRead){}

    pesquisaAlertas = async() => {
       try{
         var response = await this.climaTempoServiceRead.getAlertas();
       }catch(error){
         console.error(error);
       }
       return response;
    }
  }