import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Sinistro } from "../model/sinistro";


interface Navio {
    name: string,
    owner: string,
    length: number,
    width: number, 
    destination: string,
    imo: string
  };

@Injectable()
export class SinistroService{
    constructor(private http:HttpClient){}

    
    public getNaviosSinistro = async(data:string) : Promise<Navio[]> => {
        const url = `${environment.api_read}HistoricoNavios/getNaviosSinistro?data=${data}`
        return this.http.get<Navio[]>(url).toPromise();
    }

    public getSinistros = async() : Promise<Sinistro[]> => {
        const url = `${environment.api_read}Sinistro/get`
        return this.http.get<Sinistro[]>(url).toPromise();
    }

    public postSinistro(sinistro: Sinistro) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              observe: 'response'
            })};
        const url = `${environment.api_read}Sinistro/postSinistro`;
        return this.http.post<Sinistro>(url, sinistro, httpOptions).toPromise(); 
    }
}