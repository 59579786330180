import { Authenticate } from '../model/authenticate';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import {environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { throwError } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    readonly JWT_TOKEN = 'JWT_TOKEN';
    readonly REFRESH_TOKEN = 'REFRESH_TOKEN';


    constructor(private http: HttpClient,
        private router: Router) { }

    login(form: NgForm) {
        const url = `${environment.api_read}Authenticate/Login`;
        const credentials = JSON.stringify(form.value);
        return this.http.post(url, credentials, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).toPromise();
    }

    refreshToken() {
        const url = `${environment.api_read}Authenticate/RefreshToken`;
        const token = this.getJwtToken();
        const refreshToken = this.getRefreshToken();

        const data: Authenticate = {
          token: token,
          refreshToken: refreshToken
        };

        return this.http.post(url, data)
        .pipe(
          tap((response: {success: boolean, data: Authenticate}) => {
            this.storeJwtToken(response.data.token);
            this.storeRefreshToken(response.data.refreshToken);
          }),
          catchError((error: HttpErrorResponse) => throwError(error.error)),
          catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
        );
    }

    getUsuario(email: string) {
        const url = `${environment.api_read}Usuario/GetPorEmail?Email=${email}`;
        const token = localStorage.getItem('jwt');
        this.http.get(url, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            })
        }).subscribe( response => {
            return response;
        }, err => {
            console.log(err);
        });
    }

    logout() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
    }

    getJwtToken() {
      return localStorage.getItem(this.JWT_TOKEN);
    }
    getRefreshToken() {
      return localStorage.getItem(this.REFRESH_TOKEN);
    }

    storeJwtToken(jwt: string) {
      localStorage.setItem(this.JWT_TOKEN, jwt);
    }

    storeRefreshToken(refreshToken: string) {
      localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    }
}
