import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
export interface AlertModel {
  title: string;
  mensagem: string;
}
@Component({
    selector: 'confirm',
    templateUrl: './confirmacao.html'
})
export class ConfirmacaoComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {
  title: string;
  mensagem: string;
  constructor() {
    super();
  }


  confirmar() {
    this.result = true;
    this.close();
  }

  negar() {
    this.result = false;
    this.close();
  }

}
