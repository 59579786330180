import { EmpresaService } from "src/app/services/empresa.service";
import { Component, Inject, OnInit } from "@angular/core";
import { ClimaTempo } from "src/app/model/clima-tempo";
import { ClimaTempoHoje } from "src/app/model/clima-tempo-hoje";
import { ClimaTempoServiceRead } from "src/app/services/clima-tempo-read.service";
import { Router } from "@angular/router";
import { UsuarioServiceRead } from "src/app/services/usuario-read.service";
import { ClimaDiario } from "src/app/model/clima-diario";
import { Empresa } from "src/app/model/empresa";
import { SimpleModalService } from "ngx-simple-modal";
import { AvisoErroComponent } from "src/app/modals/aviso-erro/aviso-erro";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpErrorHandler } from "../http-error-handler";
@Component({
  selector: "app-menu-superior",
  templateUrl: "./menu-superior.component.html",
})
export class MenuSuperiorComponent implements OnInit {
  public empresasTocandoAlarme: Empresa[] = [];
  // public empresasNavioAtracado: Empresa[] = [];
  public empresas: Empresa[] = [];
  public imagemAlarme: string;
  public estado: string;
  public alarme: string;
  public hoje: ClimaDiario = new ClimaDiario();
  public semana: ClimaTempo[] = [];
  public url(icone: string): string {
    // console.log('url', icone);
    return "assets/images/previsao/" + icone + ".png";
  }

  async ngOnInit() {
    this.hoje = await this.pesquisaClimaTempoHoje();
    this.semana = await this.pesquisaClimaTempoProxDias();
    this.carregaDados();
    setInterval(() => {
      this.carregaDados();
    }, 5000);
  }

  constructor(
    private climaTempoServiceRead: ClimaTempoServiceRead,
    private usuarioServiceRead: UsuarioServiceRead,
    private empresaService: EmpresaService,
    public router: Router,
    private simpleModalService: SimpleModalService
  ) {}

  carregaDados = async () => {
    this.usuarioServiceRead
      .getAlarme()
      .then((empresas) => {
        // console.log(empresas);
        this.empresas = empresas;
        this.empresasTocandoAlarme = this.empresas.filter(
          (empresa) => empresa.tocandoAlarme
        );
      })
      .catch(() => {
        this.handleAlarmeDesligado();
      });

    if (this.empresasTocandoAlarme && this.empresasTocandoAlarme.length > 0) {
      this.handleAlarmeLigado();
    } else {
      this.handleAlarmeDesligado();
    }
  };

  handleAlarmeLigado() {
    const body = document.getElementById("body") as HTMLElement;
    this.alarme = "DESLIGAR";
    this.imagemAlarme = "assets/images/alerta-on.png";
    this.estado = "ativado";
    body.className = "background-alerta";
  }

  handleAlarmeDesligado() {
    const body = document.getElementById("body") as HTMLElement;
    body.classList.remove("background-alerta");
    this.alarme = "LIGAR";
    this.imagemAlarme = "assets/images/alerta-off.png";
    this.estado = "desativado";
  }
  mudarNavioAtracadoFlag(empresaAlterada: Empresa) {
    const navioAtracado = this.empresas.filter(
      (empresa) => empresa.id === empresaAlterada.id
    )[0].temNavioAtracado;
    this.empresas.filter(
      (empresa) => empresa.id === empresaAlterada.id
    )[0].temNavioAtracado = !navioAtracado;
  }

  atualizarEmpresas() {
    this.empresaService
      .atualizarNavioAtracado({ empresas: this.empresas })
      .subscribe(
        (empresas) => (this.empresas = empresas),
        (error) => {
          HttpErrorHandler.exibirMensagemHttpError(
            this.simpleModalService,
            error
          );
        }
      );
  }

  mudarAlarme = async () => {
    try {
      const response = await this.usuarioServiceRead.setAlarme();
    } catch (error) {
      console.log(error);
    }
    this.carregaDados();
  };
  testarAlarme = async () => {
    try {
      const response = await this.usuarioServiceRead.testAlarme();
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  pesquisaClimaTempoProxDias = async () => {
    try {
      const response =
        await this.climaTempoServiceRead.getPrevisaoProximoDias();
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  pesquisaClimaTempoHoje = async () => {
    try {
      const response = await this.climaTempoServiceRead.getPrevisaoAgora();
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}
