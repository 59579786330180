import { HttpErrorHandler } from './../../shared/http-error-handler';
import { SireneService } from './../../services/sirene.service';
import { Sirene } from 'src/app/model/sirene';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { Empresa } from 'src/app/model/empresa';
export interface AlertModel {
  title: string;
  empresa: Empresa;
}
@Component({
    selector: 'confirm',
    templateUrl: './adicionar-sirene.html'
})
export class AdicionarSireneComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {

  title: string;
  empresa: Empresa;
  sireneForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private sireneService: SireneService,
    private simpleModalService: SimpleModalService) {
    super();
  }

  ngOnInit() {

    this.sireneForm = this.formBuilder.group({
      empresaId: [this.empresa.id],
      descricao: [null, Validators.required],
      telefone: [null, [Validators.required, Validators.min(0)]],
      comandoLigar: [null, Validators.required],
      comandoDesligar: [null, Validators.required]
    });
  }

  onSubmit() {
    if (this.sireneForm.valid) {
      this.postSirene();
      this.result = true;
      this.close();
    } else {
      this.verificaValidacoesForm(this.sireneForm);
    }
  }

  postSirene() {
    const sinere = new Sirene(this.sireneForm.value);

    this.sireneService.postSirene(sinere).subscribe(
      () => {},
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

}
