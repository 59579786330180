import { Role } from './model/Role';
import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { CondicoesComponent } from './pages/condicoes/condicoes.component';
import { HistoricoComponent } from './pages/historico/historico.component';
import { MonitoramentoComponent } from './pages/Monitoramento/monitoramento.component';
import { AnterioresComponent } from './pages/navios-anteriores/anteriores.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { SirenesComponent } from './pages/sirenes/sirenes.component';
import { EmpresasComponent } from './pages/empresas/empresas.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { AuthGuard } from './guard/auth-guard';
import { HistoricoTrafegoComponent } from './pages/historico-trafego/historico-trafego.component';
import { RegistroSinistrosComponent } from './pages/registro-sinistros/registro-sinistros.component';

export const rootRouterConfig: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'monitoramento', component: MonitoramentoComponent, canActivate: [AuthGuard]  },
  { path: 'anteriores', component: AnterioresComponent, canActivate: [AuthGuard] },
  { path: 'historico', component: HistoricoComponent, canActivate: [AuthGuard] },
  { path: 'condicoes', component: CondicoesComponent, canActivate: [AuthGuard] },
  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [AuthGuard]},
  { path: 'sirenes', component: SirenesComponent, canActivate: [AuthGuard]},
  { path: 'empresas', component: EmpresasComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin, Role.Root]}},
  { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin, Role.Root]}},
  { path: 'trafego', component: HistoricoTrafegoComponent, canActivate: [AuthGuard]},
  { path: 'registrosinistros', component: RegistroSinistrosComponent, canActivate: [AuthGuard]},
];
