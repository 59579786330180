import { Component, Inject, OnInit } from "@angular/core";
import {
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameHour,
  parseISO,
} from "date-fns";
import { enUS } from "date-fns/locale";
import * as moment from "moment";

import { Navio } from "src/app/model/navio";
import { HistoricoAlarme } from "src/app/model/historico-alarme";

import { NavioServiceRead } from "src/app/services/navio-read.service";
import { AlarmeServiceRead } from "src/app/services/alarmes-read.service";

@Component({
  selector: "app-historico",
  templateUrl: "./historico.component.html",
  styleUrls: ["./historico.component.css"],
})
export class HistoricoComponent implements OnInit {
  public navios: Navio[] = [];
  public Alertas: HistoricoAlarme[] = [];
  public filteredAlertas: HistoricoAlarme[] = [];
  public spinner = true;

  public nomeNavio = "";
  public dateInit = "";
  public dateEnd = "";
  public hourInit = "";
  public hourEnd = "";

  constructor(
    private alarmeServiceRead: AlarmeServiceRead,
    private navioServiceRead: NavioServiceRead
  ) {}

  async ngOnInit() {
    this.navios = await this.pesquisaHistoricoNavio();
    this.Alertas = await this.pesquisaHistoricoAlarme();
    this.filteredAlertas = this.Alertas;
    setInterval(() => {
      this.spinner = false;
    }, 2000);
  }

  handleClickFilter = async () => {
    this.filteredAlertas = [];

    const checkFilter = (alerta: Partial<HistoricoAlarme>) => {
      const dateAlert = alerta.data.split("/").reverse().join("-");

      const [hourInitAlert, minuteInitAlert] = alerta.horaInicio.split(":");
      const [hourEndAlert, minuteEndAlert] = alerta.horaInicio.split(":");

      const timeInitAlert = `${hourInitAlert}:${minuteInitAlert}`;
      const timeEndAlert = `${hourEndAlert}:${minuteEndAlert}`;

      if (
        this.dateInit &&
        !this.hourInit &&
        dateAlert >= this.dateInit &&
        dateAlert <= this.dateEnd
      ) {
        return true;
      } else if (
        this.dateInit &&
        this.hourInit &&
        dateAlert >= this.dateInit &&
        dateAlert <= this.dateEnd &&
        timeInitAlert >= this.hourInit &&
        timeInitAlert <= this.hourEnd &&
        timeEndAlert >= this.hourInit &&
        timeEndAlert <= this.hourEnd
      ) {
        return true;
      }
    };

    this.filteredAlertas = await Promise.all(this.Alertas.filter(checkFilter));
  };

  public url(destino: string): string {
    let icone = "circle-arrow-down";
    if (destino === "SANTOS") {
      icone = "circle-arrow-up";
    }
    return "assets/images/" + icone + ".png";
  }

  pesquisaHistoricoNavio = async () => {
    try {
      const response = await this.navioServiceRead.getHistoricoPorUsuario();
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  pesquisaHistoricoAlarme = async () => {
    try {
      const response = await this.alarmeServiceRead.getHistoricoAlarme();
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}
