import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment } from "src/environments/environment";
import { TabuaMares } from "../model/tabua-mares";
import { Atual } from "../model/atual";


@Injectable()
export class TabuaMareService{
    constructor(private http:HttpClient){}
    // let token = localStorage.getItem("jwt");
    // let email = localStorage.getItem("user");

    public getTabuaMare = async(): Promise<object[]> => {
        const url = `${environment.api_read}TabuaMares/getPorSemana`;
        return this.http.get<object[]>(url).toPromise();
    }

    public getAtual = async(): Promise<Atual> => {
        const url = `${environment.api_read}TabuaMares/getAtual`;
        return this.http.get<Atual>(url).toPromise();
    }
}