import { EmpresaService } from './../../services/empresa.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Empresa } from './../../model/empresa';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';
export interface AlertModel {
  title: string;
}
@Component({
    selector: 'confirm',
    templateUrl: './adicionar-empresa.html'
})
export class AdicionarEmpresaComponent extends SimpleModalComponent<AlertModel, boolean>  implements AlertModel {

  title: string;
  empresaForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private empresaService: EmpresaService,
    private simpleModalService: SimpleModalService) {
    super();
  }

  ngOnInit() {
    this.empresaForm = this.formBuilder.group({
      nome: [null, [Validators.required]],
      larguraMaxAlerta: [null, [Validators.required, Validators.min(0)]],
      latMax: [null, [Validators.required]],
      latMin: [null, [Validators.required]],
      lonMax: [null, [Validators.required]],
      lonMin: [null, [Validators.required]],
      minutosDuracaoAlarme: [null, Validators.required],
      velocidadeMax: [null, [Validators.required, Validators.min(0)]],
      comprimentoMax : [null, [Validators.required]],
      distanciaSeguranca: [0, [Validators.min(0)]],
      velocidadeSeguranca: [0, [Validators.min(0)]],
      horarioTesteSirene: [null]
    });
  }

  onSubmit() {
    if (this.empresaForm.valid) {
      this.postEmpresa();
      this.result = true;
      this.close();
    } else {
      this.verificaValidacoesForm(this.empresaForm);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  initEmpresa(): Empresa {
    return  new Empresa(this.empresaForm.value);
  }

  postEmpresa() {

    const empresa = this.initEmpresa();

    this.empresaService.postEmpresa(empresa)
    .subscribe(
      empresaCriada => console.log(empresaCriada),
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
}

}
