import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import { AuthenticationService } from '../services/auth.read.service';
import { Subject } from 'rxjs';
import { AvisoErroComponent } from '../modals/aviso-erro/aviso-erro';
import { SimpleModalService } from 'ngx-simple-modal';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private jwtHelper: JwtHelperService,
      private router: Router,
      private authService: AuthenticationService,
      private simpleModalService: SimpleModalService) {

        this.jwtHelper = new JwtHelperService();
  }
  canActivate(route: ActivatedRouteSnapshot) {
    const token = this.authService.getJwtToken();

    const userRoles: string[] = this.jwtHelper.decodeToken(token).role;
    const routeRoles: string[] | undefined = route.data.roles;

    if (!token) {
      this.router.navigate(['login']);
      return false;
    }

    if (routeRoles) {
      if (!routeRoles.some(r => userRoles.includes(r))) {
        this.exibirAvisoAcessoNegado();
        return false;
      }
    }


    if (!this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    const subject = new Subject<boolean>();

    this.authService.refreshToken().subscribe(
      () => subject.next(true),
      () => { this.router.navigate(['login']); return subject.next(false); }
    );

    return subject.asObservable();
  }

  exibirAvisoAcessoNegado() {
    this.simpleModalService.addModal(AvisoErroComponent, {
      title: 'Acesso Negado',
      mensagens: ['Página apenas para administradores.']
    }, { closeOnClickOutside: true });

  }
}
