import { UsuarioService } from './../../services/usuario.service';
import { Usuario } from './../../model/usuario';
import { Empresa } from 'src/app/model/empresa';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidations } from 'src/app/shared/form-validations';
import { EmpresaService } from 'src/app/services/empresa.service';
import { UsuarioServiceRead } from 'src/app/services/usuario-read.service';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';
export interface AlertModel {
  title: string;
}
@Component({
    selector: 'confirm',
    templateUrl: './adicionar-usuario.html'
})
export class AdicionarUsuarioComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {

  title: string;
  usuarioForm: FormGroup;

  public empresas: Empresa[] = [];
  public empresasSelecionadas: Empresa[];

  constructor(private formBuilder: FormBuilder,
    private empresaService: EmpresaService,
    private usuarioService: UsuarioService,
    private simpleModalService: SimpleModalService) {
    super();
  }

  ngOnInit() {

    this.carregarEmpresas();

    this.usuarioForm = this.formBuilder.group({
      nome: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null, [Validators.required]],
      empresas: [this.empresasSelecionadas, [Validators.required]],
      senha: [null, [Validators.required, FormValidations.passswordValidator]],
      confirmarSenha: [null, [Validators.required, FormValidations.equalsTo('senha')]],
      admin: [false],
      visualizador: [false],
      alarme: [false],
      clima: [false],
      navio: [false]
    });
  }

  carregarEmpresas() {
    this.empresaService.getEmpresas()
      .subscribe(
        empresas => this.empresas = empresas,
        error => console.log(error)
      );
  }

  onSubmit() {
    if (this.usuarioForm.valid) {
      console.log(this.usuarioForm.value);
      this.postUsuario();
    } else {
      this.verificaValidacoesForm(this.usuarioForm);
    }
  }

  postUsuario() {
    const usuario = new Usuario(this.usuarioForm.value);

    this.usuarioService.postUsuario(usuario)
      .subscribe(
        usuarioCriado => {this.result = true; this.close(); },
        error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
      );
  }


  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

}
