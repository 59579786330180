export class Sinistro {
    data:string;
    descricao:string;
    nomenavio:string;
    dononavio:string;
    largura:number;
    comprimento:number;
    destino:string;
    imo:string;
    usuario: string;
    email:string;
}
