import { Usuario } from './../model/usuario';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  public postUsuario(usuario: Usuario) {

    const url = `${environment.api_read}Authenticate/Registrar`;

    return this.http.post(url, usuario)
      .pipe(
        map((response: {success: boolean, data: Usuario}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public getUsuarios() {

    const url = `${environment.api_read}Usuario/get`;

    return this.http.get(url)
      .pipe(
        map((response: {success: boolean, data: Usuario[]}) =>response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

  public deleteUsuario(usuarioId: number) {

    const url = `${environment.api_read}Usuario/Excluir?usuarioId=${usuarioId}`;

    return this.http.delete(url)
      .pipe(
        map((response: {success: boolean, data: Usuario[]}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[] | string}) => throwError(error.data))
      );
  }

  public updateUsuario(empresa: Usuario) {
    const url = `${environment.api_read}Usuario/Atualizar`;

    return this.http.put(url, empresa)
      .pipe(
        map((response: {success: boolean, data: Usuario}) => response.data),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
        catchError((error: {success: boolean, data: string[]}) => throwError(error.data))
      );
  }

}
