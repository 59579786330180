import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
export interface AlertModel {
  title: string;
  mensagens: string[] | string;
}
@Component({
    selector: 'confirm',
    templateUrl: './aviso-erro.html'
})
export class AvisoErroComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {

  title: string;
  mensagens: string[] | string = ['Ocorreu um erro inesperado.'];

  constructor() {
    super();
  }

  okClick() {
    this.result = true;
    this.close();
  }

}
