import { Usuario } from 'src/app/model/usuario';
import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Empresa } from 'src/app/model/empresa';
import { FormValidations } from 'src/app/shared/form-validations';
import { UsuarioService } from 'src/app/services/usuario.service';
import { HttpErrorHandler } from 'src/app/shared/http-error-handler';
import { EmpresaService } from 'src/app/services/empresa.service';
export interface AlertModel {
  title: string;
  usuario: object;
}
@Component({
    selector: 'confirm',
    templateUrl: './editar-usuario.html'
})
export class EditarUsuarioComponent extends SimpleModalComponent<AlertModel, boolean> implements AlertModel {

  title: string;
  usuario: Usuario;
  empresas: Empresa[] = [];
  editarUsuarioForm: FormGroup;

  public empresasSelecionadas: Empresa[];

  constructor(private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private empresaService: EmpresaService,
    private simpleModalService: SimpleModalService) {
    super();
  }

  ngOnInit() {
    this.editarUsuarioForm = this.formBuilder.group({
      id: [0],
      nome: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null, [Validators.required]],
      empresas: [this.empresasSelecionadas, [Validators.required]],
      senha: [null, [FormValidations.passswordValidator]],
      confirmarSenha: [null, [FormValidations.equalsTo('senha')]],
      admin: [false],
      visualizador: [false],
      alarme: [false],
      clima: [false],
      navio: [false]
    });

    this.carregarEmpresas();
    this.populaDadosForm(this.usuario);

  }

  populaDadosForm(usuario: Usuario) {
    console.log(usuario);
    this.editarUsuarioForm.patchValue({
      id: usuario.id,
      nome: usuario.nome,
      email: usuario.email,
      telefone: usuario.telefone,
      empresas: usuario.empresas,
      senha: usuario.senha,
      confirmarSenha: usuario.senha,
      admin: usuario.admin,
      visualizador: usuario.visualizador,
      alarme: usuario.alarme,
      clima: usuario.clima,
      navio: usuario.navio,
    });
  }

  onSubmit() {
    if (this.editarUsuarioForm.valid) {
      this.atualizarUsuario();
    } else {
      this.verificaValidacoesForm(this.editarUsuarioForm);
    }
  }

  atualizarUsuario() {

    const usuario = new Usuario(this.editarUsuarioForm.value);
    console.log(usuario);
    this.usuarioService.updateUsuario(usuario).subscribe(
      response => {
        console.log(response);
        this.result = true;
        this.close();
      },
      error => HttpErrorHandler.exibirMensagemHttpError(this.simpleModalService, error)
    );
  }

  carregarEmpresas() {
    this.empresaService.getEmpresas()
      .subscribe(
        empresas => this.empresas = empresas,
        error => console.log(error)
      );
  }


  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }
}
