import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/auth.read.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  submitted = false;

  constructor(private router: Router,
              private authenticationService: AuthenticationService) {}
  ngOnInit() {
    this.authenticationService.logout();
  }

  login(form: NgForm) {
    this.authenticationService.login(form)

    .then(response => {

      const token = (<any>response).data.token;
      const refreshToken = (<any>response).data.refreshToken;
      const userEmail = (<any>response).data.user.email;

      this.authenticationService.storeJwtToken(token);
      this.authenticationService.storeRefreshToken(refreshToken);
      localStorage.setItem('user', userEmail);

      // this.menu.carregaDados();
      this.router.navigate(['/monitoramento']);
    })
    .catch(errorReponse => {
      console.log(errorReponse.error.data);
      alert(errorReponse.error.data);
    });
  }

}
