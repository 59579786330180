import {Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html'
  })
  export class MenuLateralComponent {
    constructor( public router:Router){}
  }
