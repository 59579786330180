import {Component, OnInit } from '@angular/core';
import {ClimaTempo} from 'src/app/model/clima-tempo';
import { ClimaTempoServiceRead } from 'src/app/services/clima-tempo-read.service';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { ClimaTempoHoje } from 'src/app/model/clima-tempo-hoje';
import { ClimaDiario } from 'src/app/model/clima-diario';
declare var google: any;
@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html'
})

export class GraficoComponent implements OnInit{
  constructor(private climaTempoServiceRead:ClimaTempoServiceRead){}
  public hoje:ClimaTempo = new ClimaTempo;
  public semana:ClimaTempo[] =[];
  public agora:ClimaDiario;
  public options;
  async ngOnInit() {
    this.hoje = await this.pesquisaClimaTempoHoje();
    this.semana = await this.pesquisaClimaTempoSemana();
    this.agora = await this.pesquisaClimaTempoAgora();
    google.charts.load('current', {packages: ['corechart', 'line']});
    google.charts.setOnLoadCallback(this.gerarGraficoPrevisao);
    google.charts.setOnLoadCallback(this.gerarGraficoChuva);
    google.charts.setOnLoadCallback(this.gerarGraficoVento);
  }
  pesquisaClimaTempoAgora = async() => {
    try{
      var response = await this.climaTempoServiceRead.getPrevisaoAgora();
    }catch(error){
      console.error(error);
    }
    return response;
  }
  pesquisaClimaTempoHoje = async() => {
    try{
      var response = await this.climaTempoServiceRead.getPrevisaoHoje();
    }catch(error){
      console.error(error);
    }
    return response[0];
  }
  pesquisaClimaTempoSemana = async() => {
    try{
      var response = await this.climaTempoServiceRead.getPrevisaoSemana();
    }catch(error){
      console.error(error);
    }
    return response;
  }
  protected gerarGraficoPrevisao = () => {
    var dataPrevisao = new google.visualization.DataTable();
     dataPrevisao.addColumn('string', 'Periodo');
     dataPrevisao.addColumn('number', 'Temperatura');
     dataPrevisao.addRows([
       ['Minimo Amanhecer',this.hoje.minimoAmanhecer],
       ['Maximo Amanhecer',this.hoje.maximoAmanhecer],
       ['Minimo Manhã',this.hoje.minimoManha],
       ['Maximo Manhã',this.hoje.maximoManha],
       ['Minimo Tarde',this.hoje.minimoTarde],
       ['Maximo Tarde',this.hoje.maximoTarde],
       ['Minimo Noite',this.hoje.minimoNoite],
       ['Maximo Noite',this.hoje.maximoNoite]
     ]);
     this.options = {
       pointsVisible:true,
       curveType: 'function',
       hAxis: {
         textStyle:{
          color: 'white'
         }
       },
       vAxis: {
         textStyle:{
         color: 'white'
         },
         gridlines:{
           color:'gray'
         }
       },
       backgroundColor:{
         fill: 'none'
       },
       colors:['#009557'],
       legend:{
         position:'none'
       },
       tooltip: { isHtml: true }
     }
     var chart = new google.visualization.LineChart(document.getElementById('temperatura'));
     chart.draw(dataPrevisao, this.options);
   }
  
  protected gerarGraficoChuva = () => {
    var dataPrevisao = new google.visualization.DataTable();
    dataPrevisao.addColumn('string', 'Dia');
    dataPrevisao.addColumn('number', 'Chance de Chuva');
    // dataPrevisao.addColumn('number','Quantidade');
    dataPrevisao.addColumn({ type: 'string', role: 'tooltip', 'p': { 'html': true } });
    this.semana.forEach((item)=>{
      dataPrevisao.addRow([item.dia,item.chanceChuva, this.tooltipChuva(item.chanceChuva, item.precipitacao)]);
    })
    var chart = new google.visualization.LineChart(document.getElementById('chuvas'));
    chart.draw(dataPrevisao, this.options);
  }

  protected tooltipChuva(chanceDeChuva, quantidadeDeChuva) {
    return '<div style="padding:5px 5px 5px 5px;background-color:#184f68;">' +
            '<span style="font-size: 14px; color:#fff;margin-right: 10px;">'+
                '<i class="icon ion-ios-partly-sunny" style="color: #60a5ce; font-size: 20px;"></i>'+
                ' Chance de Chuva: '+
            '</span>' +
            '<span style="color: #60a5ce; font-size: 22px; font-weight: bold;">' +
                chanceDeChuva + 
            '%</span>'+
        '</div>' +
        '<hr>'+
        '<div style="padding:5px 5px 5px 5px;background-color:#184f68;">'+
            '<span style="font-size: 14px; color:#fff;margin-right: 10px;">'+
                '<i class="icon ion-ios-rainy" style="color: #60a5ce; font-size: 20px;"></i>'+
                ' Quantidade de Chuva: '+
            '</span>' +
            '<span style="color: #60a5ce; font-size: 22px; font-weight: bold;">' + 
                quantidadeDeChuva + 
            'mm</span>'+
        '</div>'
}

  protected gerarGraficoVento = () => {
    var dataPrevisao = new google.visualization.DataTable();
    dataPrevisao.addColumn('string', 'Dia');
    dataPrevisao.addColumn('number', 'Velocidade do Vento');
    dataPrevisao.addRows([
      ['Amanhecer',this.hoje.vrAmanhecer],
      ['Manhã',this.hoje.vrManha],
      ['Tarde',this.hoje.vrTarde],
      ['Noite',this.hoje.vrNoite]
    ]);
    var chart = new google.visualization.LineChart(document.getElementById('vento'));
    chart.draw(dataPrevisao, this.options);
  }
}